<template>
  <div class="example-full">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <div class="upload">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Nazwa</th>
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!files.length">
              <td colspan="7">
                <div class="text-center p-5">
                  <h4>Drop files anywhere to upload <br />or</h4>
                  <label :for="name" class="btn btn-lg btn-primary"
                    >Select Files</label
                  >
                </div>
              </td>
            </tr>
            <tr v-for="(file, index) in files" :key="file.id">
              <td>{{ index }}</td>
              <td>
                <div class="filename">{{ file.name }}</div>
                <div
                  class="progress"
                  v-if="file.active || file.progress !== '0.00'"
                >
                  <div
                    :class="{
                      'progress-bar': true,
                      'progress-bar-striped': true,
                      'bg-danger': file.error,
                      'progress-bar-animated': file.active
                    }"
                    role="progressbar"
                    :style="{ width: file.progress + '%' }"
                  >
                    {{ file.progress }}%
                  </div>
                </div>
              </td>
              <td v-if="file.error">Błąd</td>
              <td v-else-if="file.success">Wysłany</td>
              <td v-else-if="file.active">Wysyłam</td>
              <td v-else></td>
              <td>
                <div class="btn-group">
                  <a
                    class="btn btn-warning btn-xs"
                    href="#"
                    v-if="
                      file.error &&
                        file.error !== 'compressing' &&
                        $refs.upload.features.html5
                    "
                    @click.prevent="restartUpload(file)"
                  >
                    Ponów wysłanie
                  </a>
                  <a
                    class="btn btn-danger btn-xs"
                    href="#"
                    @click.prevent="$refs.upload.remove(file)"
                  >
                    Usuń
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="example-foorer">
        <div class="btn-group" v-if="!reload">
          <file-upload
            class="btn btn-primary"
            name="file"
            :post-action="postAction"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :size="size || 0"
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :headers="headers"
            :drop="drop"
            :drop-directory="dropDirectory"
            :add-index="addIndex"
            @input-filter="inputFilter"
            @input-file="inputFile"
            @input="updatetValue"
            ref="upload"
          >
            <i class="fa fa-plus"></i>
            Dodaj plik
          </file-upload>
        </div>
        <button
          type="button"
          class="btn btn-success"
          v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="startUpload()"
        >
          <i class="fa fa-upload" aria-hidden="true"></i>
          Wyślij
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-else
          @click.prevent="$refs.upload.active = false"
        >
          <i class="fa fa-stop" aria-hidden="true"></i>
          Zatrzymaj wysyłanie
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.example-full .btn-group {
  margin-right: 0.6rem;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
</style>

<script>
import Cropper from "cropperjs";
import ImageCompressor from "@xkeshi/image-compressor";
import FileUpload from "vue-upload-component";

export default {
  name: "SimpleFileUpload",
  components: {
    FileUpload
  },
  data() {
    return {
      files: [],
      upload: false,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: "file",
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: ""
      },
      editFile: {
        show: false,
        name: ""
      },
      reload: false
    };
  },
  props: {
    url: {
      type: String,
      default: "vm/report/files/"
    },
    accept: {
      type: String,
      default: "image/png,image/jpeg,csv"
    },
    extensions: {
      type: String,
      default: "jpg,jpeg,png,csv"
    },
    add_param: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    headers() {
      return {
        Authorization: `${this.$http.getToken()}`,
        Accept: `application/json`
      };
    },
    postAction() {
      const url = `${this.$http.getBaseUrl()}${this.url}`;
      return this.add_param ? `${url}${this.$route.params.id}` : url;
    },
    putAction() {
      return `${this.$http.getBaseUrl()}${this.url}${this.$route.params.id}`;
    }
  },
  watch: {
    files: {
      handler: function() {
        if (this.$refs.upload && this.$refs.upload.uploaded && this.upload) {
          this.reload = true;
          this.upload = false;
          const fileBuff = JSON.parse(JSON.stringify(this.files));
          console.log("fileBuff", fileBuff, this.files);
          this.files = this.files.map((file, key) => {
            if (
              file.error &&
              file.error !== "compressing" &&
              this.$refs.upload.features.html5
            ) {
              return file;
            }
            this.files.splice(key, 1);
          });
          this.$emit("uploaded", fileBuff);
          this.files[0] === undefined ? (this.files = []) : this.files;
          this.reload = false;
          this.$forceUpdate();
        }
      },
      deep: true
    },
    "editFile.show"(newValue, oldValue) {
      // 关闭了 自动删除 error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ""
        });
      }
      if (newValue) {
        this.$nextTick(function() {
          if (!this.$refs.editImage) {
            return;
          }
          const cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false
          });
          this.editFile = {
            ...this.editFile,
            cropper
          };
        });
      }
    },
    "addData.show"(show) {
      if (show) {
        this.addData.name = "";
        this.addData.type = "";
        this.addData.content = "";
      }
    }
  },
  created() {
    this.$emit("loading", false);
    console.log("test!!!", this.$http);
  },
  methods: {
    restartUpload(file) {
      this.$refs.upload.update(file, {
        active: true,
        error: "",
        progress: "0.00"
      });
      this.upload = true;
    },
    startUpload() {
      this.$refs.upload.active = true;
      this.upload = true;
    },
    getData() {},
    updatetValue(value) {
      this.files = value;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 2048,
            maxHeight: 2048
          });
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type
              });
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress"
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: "edit" });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        this.editFile.show = false;
        return;
      }
      const data = {
        name: this.editFile.name
      };
      if (this.editFile.cropper) {
        const binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(",")[1]
        );
        const arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
    }
  }
};
</script>
