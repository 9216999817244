var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"example-full"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.upload && _vm.$refs.upload.dropActive),expression:"$refs.upload && $refs.upload.dropActive"}],staticClass:"drop-active"},[_c('h3',[_vm._v("Drop files to upload")])]),_c('div',{staticClass:"upload"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',[(!_vm.files.length)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"text-center p-5"},[_vm._m(1),_c('label',{staticClass:"btn btn-lg btn-primary",attrs:{"for":_vm.name}},[_vm._v("Select Files")])])])]):_vm._e(),_vm._l((_vm.files),function(file,index){return _c('tr',{key:file.id},[_c('td',[_vm._v(_vm._s(index))]),_c('td',[_c('div',{staticClass:"filename"},[_vm._v(_vm._s(file.name))]),(file.active || file.progress !== '0.00')?_c('div',{staticClass:"progress"},[_c('div',{class:{
                    'progress-bar': true,
                    'progress-bar-striped': true,
                    'bg-danger': file.error,
                    'progress-bar-animated': file.active
                  },style:({ width: file.progress + '%' }),attrs:{"role":"progressbar"}},[_vm._v(" "+_vm._s(file.progress)+"% ")])]):_vm._e()]),(file.error)?_c('td',[_vm._v("Błąd")]):(file.success)?_c('td',[_vm._v("Wysłany")]):(file.active)?_c('td',[_vm._v("Wysyłam")]):_c('td'),_c('td',[_c('div',{staticClass:"btn-group"},[(
                    file.error &&
                      file.error !== 'compressing' &&
                      _vm.$refs.upload.features.html5
                  )?_c('a',{staticClass:"btn btn-warning btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.restartUpload(file)}}},[_vm._v(" Ponów wysłanie ")]):_vm._e(),_c('a',{staticClass:"btn btn-danger btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.upload.remove(file)}}},[_vm._v(" Usuń ")])])])])})],2)])]),_c('div',{staticClass:"example-foorer"},[(!_vm.reload)?_c('div',{staticClass:"btn-group"},[_c('file-upload',{ref:"upload",staticClass:"btn btn-primary",attrs:{"name":"file","post-action":_vm.postAction,"extensions":_vm.extensions,"accept":_vm.accept,"multiple":_vm.multiple,"directory":_vm.directory,"size":_vm.size || 0,"thread":_vm.thread < 1 ? 1 : _vm.thread > 5 ? 5 : _vm.thread,"headers":_vm.headers,"drop":_vm.drop,"drop-directory":_vm.dropDirectory,"add-index":_vm.addIndex},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile,"input":_vm.updatetValue}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Dodaj plik ")])],1):_vm._e(),(!_vm.$refs.upload || !_vm.$refs.upload.active)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.startUpload()}}},[_c('i',{staticClass:"fa fa-upload",attrs:{"aria-hidden":"true"}}),_vm._v(" Wyślij ")]):_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.$refs.upload.active = false}}},[_c('i',{staticClass:"fa fa-stop",attrs:{"aria-hidden":"true"}}),_vm._v(" Zatrzymaj wysyłanie ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Nazwa")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("#")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_vm._v("Drop files anywhere to upload "),_c('br'),_vm._v("or")])}]

export { render, staticRenderFns }