<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="`${$t('upload-files')}`">
        <template slot="content" slot-scope="{ setLoading }">
          <SimpleFileUpload
            ref="uploadFile"
            slot="content"
            :url="url"
            :add_param="false"
            :extensions="extensions"
            :accept="accept"
            @loading="setLoading"
            @uploaded="addFiles($event)"
          />
        </template>
      </ibox>
    </div>
    <div class="col-12">
      <ibox :title="`${$t('files-list')}`">
        <template slot="content" slot-scope="{ setLoading }">
          <DataTables
            ref="dataTable"
            :url="'/qr-code/files/list'"
            @loading="setLoading"
            :columns_list="columns_list"
            :theadRow="false"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import SimpleFileUpload from "@/components/base/SimpleFileUpload";
import DataTables from "@/components/base/DataTables.vue";

export default {
  name: "QrCodeUploadFiles",
  components: {
    Ibox,
    SimpleFileUpload,
    DataTables
  },
  data() {
    return {
      movies: [],
      loading: false,
      fileUploaded: false,
      extensions: "pdf,doc,jpeg,jpg,png,txt",
      accept:
        "image/png,image/jpeg,application/msword,application/pdf,text/plain",
      url: "qr-code/files/upload",
      columns_list: [
        {
          key: "name",
          name: "Nazwa",
          type: "text",
          sortable: false,
          filterable: false
        },
        {
          key: "size",
          name: "Rozmiar",
          type: "text",
          sortable: false,
          filterable: false
        },
        {
          key: "url",
          name: "Address do pliku",
          type: "text",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  methods: {
    addFiles() {
      this.$refs.dataTable.getData();
    }
  }
};
</script>

<style>
.dataTables_length {
  display: none;
}
.dataTables_info {
  display: none;
}
.pagination {
  display: none;
}
</style>
